import React, { Fragment, useState } from 'react'
import PropTypes from 'prop-types'
/* import Img from "gatsby-image" */
import { graphql, navigate }  from 'gatsby'
import ds from '../theme'
import UnderstandTheDifference from '../components/UnderstandTheDifference'

import Layout from '../components/Layout'
import Seo from '../components/Seo'
import Row from '../components/Row'
import Column from '../components/Column'
import Div from '../components/Div/Div.styles'
import MuiSelect from '../components/Dropdown'
import H3 from '../components/H3'
import H5 from '../components/H5'
import Subtitle from '../components/SubTitle'
import Subtitle2 from '../components/SubTitle2'
import Paragraph from '../components/Paragraph'
import Body2 from '../components/Body2'
import Button from '../components/Button/Button'
import Hr from '../components/Hr'
import H4 from '../components/H4'
import H6 from '../components/H6'
import ImgServidoresExclusivos from '../images/dc-servers/servidores-exclusivos.png'
import ImgMonitoramento from '../images/dc-servers/monitoramento.png'
import ImgFlexibilidade from '../images/dc-servers/flexibilidade.png'
import ImgPortalDeGerenciamento from '../images/dc-servers/inspecao.svg'
import ImgIpmi from '../images/dc-servers/ipmi.svg'
import ImgMonitoramentoTempo from '../images/dc-servers/tempo.svg'
import ImgInspecaoSeguranca from '../images/dc-servers/portal.svg'
import { css } from '@emotion/core'

import { Container, mq } from '../components/styles/Layout.styles'
import { 
  styledPrice,
  header,
  currency,
  listServer,
  listManager,
  item
} from '../components/styles/DedicatedServersPrice.styles'

const initialModals = {
  support: false,
  moreInfo: false
}

const ProductItem = ({
  productName,
  productAdvantages,
  productPrice,
  productDescription,
  productBtn,
}) => {
  return (
    <div
      style={{
        padding: '24px 16px 40px 16px',
        backgroundColor: '#ffffff',
        border: 'solid 1px rgba(0, 0, 0, 0.12)',
        borderRadius: 12,
      }}
      css={
        [css(
          mq({
            '&:not(:last-child)': {
              marginBottom: ds.space(3)
            } 
          })
        ),
        item
      ]
      }
    >
      <Div
        flexDirection='column'
        alignItems='center'
      >
        <H5
          selected
          on='onSurface'
          variant='highEmphasis'
          style={{
            letterSpacing: 0.25,
            marginBottom: 8,
            width: 296,
            textAlign: 'center'
          }}
        >
          {productName}
        </H5>
        <Subtitle
          style={{
            fontSize: 13.8,
            fontWeight: 600,
            lineHeight: 1.4,
            letterSpacing: 0.1,
            textAlign: 'center',
            color: 'rgba(0, 0, 0, 0.6)',
            width: 195,
            whiteSpace: 'pre-wrap'
          }}
        >
          {productAdvantages}
        </Subtitle>
        <Div 
          style={{
            width: 50,
            height: 1,
            borderBottom: 'solid 4px #f3f5f5',
            margin: '20px 0'
          }}
        />
        <H3
          selected
          on='onSurface'
          variant='highEmphasis'
          style={{
            letterSpacing: 0.25,
            marginBottom: 8,
            display: 'flex',
            alignItems: 'center'
          }}
        >
          <span css={currency}>R$</span>
          {productPrice}
          <span css={currency}>,90</span>
        </H3>
        <Div 
          style={{
            width: 50,
            height: 1,
            borderBottom: 'solid 4px #f3f5f5',
            margin: '20px 0'
          }}
        />
        <Body2 
          style={{
            fontSize: 14, 
            marginLeft: 24,
            marginRight: 24,
            height: 294,
            textAlign: 'left',
            lineHeight: 2.5,
            fontWeight: 500,
            color: 'rgba(0, 0, 0, 0.87)',
            whiteSpace: 'pre-wrap'
          }}>
          {productDescription}
        </Body2>
        <Button
          onClick={() => navigate('/contatos')}
          schema='white'
          outlined
          selected
          variant='base'
          on='onSurface'
          width={['100%']}
          maxWidth={['140px']}
          style={{
            display: 'flex',
            border: '1px solid rgba(0, 0, 0, 0.12)'
          }}
        >
          {productBtn}
        </Button>
      </Div>
    </div>
  )
}

const ProductItemNotPrice = ({
  productName,
  productAdvantages,
  productPrice,
  productDescription,
  productBtn,
}) => {
  return (
    <div
      style={{
        padding: '24px 16px 40px 16px',
        backgroundColor: '#ffffff',
        border: 'solid 1px rgba(0, 0, 0, 0.12)',
        borderRadius: 12,
      }}
      css={
        [css(
          mq({
            '&:not(:last-child)': {
              marginBottom: ds.space(3)
            } 
          })
        ),
        item
      ]
      }
    >
      <Div
        flexDirection='column'
        alignItems='center'
      >
        <H5
          selected
          on='onSurface'
          variant='highEmphasis'
          style={{
            letterSpacing: 0.25,
            marginBottom: 8,
            width: 296,
            textAlign: 'center'
          }}
        >
          {productName}
        </H5>
        <Subtitle
          style={{
            fontSize: 13.8,
            fontWeight: 600,
            lineHeight: 1.4,
            letterSpacing: 0.1,
            textAlign: 'center',
            color: 'rgba(0, 0, 0, 0.6)',
            width: 195,
            whiteSpace: 'pre-wrap'
          }}
        >
          {productAdvantages}
        </Subtitle>
        <Div 
          style={{
            width: 50,
            height: 1,
            borderBottom: 'solid 4px #f3f5f5',
            margin: '20px 0'
          }}
        />
        <H3
          selected
          on='onSurface'
          variant='highEmphasis'
          style={{
            letterSpacing: 0.25,
            marginBottom: 8,
            display: 'flex',
            alignItems: 'center'
          }}
        >
          {productPrice}
        </H3>
        <Div 
          style={{
            width: 50,
            height: 1,
            borderBottom: 'solid 4px #f3f5f5',
            margin: '20px 0'
          }}
        />
        <Body2 
          style={{
            fontSize: 14, 
            marginLeft: 24,
            marginRight: 24,
            height: 294,
            textAlign: 'left',
            lineHeight: 2.5,
            fontWeight: 500,
            color: 'rgba(0, 0, 0, 0.87)',
            whiteSpace: 'pre-wrap'
          }}>
          {productDescription}
        </Body2>
        <Button
          onClick={() => navigate('/contatos')}
          schema='white'
          outlined
          selected
          variant='base'
          on='onSurface'
          width={['100%']}
          maxWidth={['140px']}
          style={{
            display: 'flex',
            border: '1px solid rgba(0, 0, 0, 0.12)'
          }}
        >
          {productBtn}
        </Button>
      </Div>
    </div>
  )
}

export const PageTemplate = ({
  data
}) => {
  console.log(data)
  const [modals, setModals] = useState(initialModals)

  const setModalVisibility = modal => visible => {
    setModals({
      ...modals,
      [modal]: visible
    })
  }
  return (
    <Fragment>
      <section css={styledPrice}>
        <Container>
          <Row
            flexDirection={['column', 'row']}
            css={header}
            mb={30}
          >
            <Column
              width={[1]}
            >
              <H3>Servidores Dedicados</H3>
              <Paragraph>Seu servidor dedicado na HostDime. Mais performance e segurança. <br />Mais tecnologia e suporte de especialistas. No Brasil ou nos Estados Unidos.</Paragraph>
            </Column>
          </Row>
          <Row
            flexDirection={['column']}
            textAlign={['center']}
          >
            <Column
              width={[1]}
              mb={20}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center'
                }}
              >
                <MuiSelect
                  options={[
                    {label: 'DC Nordeste - Brasil', value: '/servidores-dedicados-nordeste'},
                    {label: 'DC São Paulo - Brasil', value: '/servidores-dedicados-sao-paulo'},
                    {label: 'DC Outlet', value: '/servidores-dedicados-outlet'}
                  ]}
                  label='DC Orlando - USA'
                  onChange={(value) => {
                    if (value.value) navigate(value.value)
                  }}
                  value={{label: 'DC Orlando - USA', value: 0}}
                  isClearable={false}
                  style={{
                    maxWidth: 328,
                    marginBottom: 20,
                    borderColor: '#FF5800'
                  }}
                />
                <Subtitle2
                  maxWidth={[200, '100%']}
                  style={{
                    color: 'rgba(0, 0, 0, 0.6)',
                    textAlign: 'center'
                  }}
                >
                  Brasil ou Estados Unidos? 
                  <span
                    onClick={() => setModalVisibility('moreInfo')(true)}
                    style={{
                      color: 'rgba(0, 0, 0, 0.87)',
                      marginLeft: 2,
                      textDecoration: 'underline',
                      cursor: 'pointer'
                    }}
                  >
                     Entenda a diferença
                  </span>
                </Subtitle2>
              </div>
            </Column>
          </Row>
          <Row
            justifyContent={'center'}
            flexDirection={['column', 'column', 'column', 'row']}
            alignItems={'center'}
            mt={30}
          >
            <Column
              mb={[30]}
            >
              <Div
                maxWidth={['100%', 328]}
              >
                <ProductItem
                  productName='Intel Xeon E3-1230 V5 3.40GHz w/HT'
                  productPrice='599'
                  productDescription={`4 Cores - 8 Total CPU Threads\n16GB DDR4 RAM\n480GB SSD ou 1TB SATA3 HDD\nHot-Swappable Drive Chassis *\n30Mbps (~10TB) Tráfego Mensal\n100Mbps Porta de Rede`}
                  productBtn='CONSULTE'
                />
              </Div>
            </Column>
            <Column
              mb={[30]}
            >
              <Div
                maxWidth={['100%', 328]}
              >
                <ProductItem
                  productName='Intel Xeon E3-1270 V5 3.60GHz w/HT'
                  productPrice='699'
                  productDescription={`4 Cores - 8 Total CPU Threads\n16GB DDR4 RAM\n480GB SSD ou 1TB SATA3 HDD\nHot-Swappable Drive Chassis *\n30Mbps (~10TB) Tráfego Mensal\n100Mbps Porta de Rede`}
                  productBtn='CONSULTE'
                />
              </Div>
            </Column>
            <Column
              mb={[30]}
            >
              <Div
                maxWidth={['100%', 328]}
              >
                <ProductItem
                  productName='2 x 6-Core Xeon E5-2620 V3 2.40GHz w/HT'
                  productPrice='1.449'
                  productDescription={`12 Cores - 24 Total CPU Threads\n32GB DDR4 RAM\n480GB SSD ou 1TB SATA3 HDD\nHot-Swappable Drive Chassis *\n30Mbps (~10TB) Tráfego Mensal\n100Mbps Porta de Rede`}
                  productBtn='CONSULTE'
                />
              </Div>
            </Column>
          </Row>
          <Row
            justifyContent={'center'}
            flexDirection={['column', 'column', 'column', 'row']}
            alignItems={'center'}
          >
            <Column
              mb={[30]}
            >
              <Div
                maxWidth={['100%', 328]}
              >
                <ProductItemNotPrice
                  productName='E5-2620 V4'
                  productAdvantages={`2 x 8-Core Xeon E5-2620 V4 2.10GHz w/HT`}
                  productPrice='---'
                  productDescription={`16 Cores - 32 Total CPU Threads\n32GB DDR4 RAM\n480GB SSD ou 1TB SATA3 HDD\nHot-Swappable Drive Chassis *\n30Mbps (~10TB) Tráfego Mensal\n100Mbps Porta de Rede`}
                  productBtn='CONSULTE'
                />
              </Div>
            </Column>
            <Column
              mb={[30]}
            >
              <Div
                maxWidth={['100%', 328]}
              >
                <ProductItemNotPrice
                  productName='E5-2630 V4'
                  productAdvantages={`2 x 10-Core Xeon E5-2630 V4 2.20GHz w/HT`}
                  productPrice='- - -'
                  productDescription={`20 Cores - 40 Total CPU Threads\n32GB DDR4 RAM\n480GB SSD ou 1TB SATA3 HDD\nHot-Swappable Drive Chassis *\n30Mbps (~10TB) Tráfego Mensal\n100Mbps Porta de Rede`}
                  productBtn='CONSULTE'
                />
              </Div>
            </Column>
            <Column
              mb={[30]}
            >
              <Div
                maxWidth={['100%', 328]}
              >
                <ProductItemNotPrice
                  productName='E5-2650 V4'
                  productAdvantages={`2x Dodeca-Core Xeon E5-2670 V3 2.30GHz w/HT`}
                  productPrice='- - -'
                  productDescription={`24 Cores - 48 Total CPU Threads\n64GB DDR4 RAM\n480GB SSD ou 1TB SATA3 HDD\nHot-Swappable Drive Chassis *\n30Mbps (~10TB) Tráfego Mensal\n100Mbps Porta de Rede`}
                  productBtn='CONSULTE'
                />
              </Div>
            </Column>
          </Row>
          <Row
            justifyContent={'center'}
            flexDirection={['column', 'column', 'column', 'row']}
            alignItems={'center'}
            pb={20}
          >
            <Column
              mb={[30]}
            >
              <Div
                maxWidth={['100%', 328]}
              >
                <ProductItemNotPrice
                  productName='E5-2660 V4'
                  productAdvantages={`2 x 14-Core Xeon E5-2660 V4 2.30GHz w/HT`}
                  productPrice='---'
                  productDescription={`28 Cores - 56 Total CPU Threads\n32GB DDR4 RAM\n480GB SSD ou 1TB SATA3 HDD\nHot-Swappable Drive Chassis *\n30Mbps (~10TB) Tráfego Mensal\n100Mbps Porta de Rede`}
                  productBtn='CONSULTE'
                />
              </Div>
            </Column>
            <Column
              mb={[30]}
            >
              <Div
                maxWidth={['100%', 328]}
              >
                <ProductItemNotPrice
                  productName='E5-2683 V4'
                  productAdvantages={`2 x 16-Core Xeon E5-2683 V4 2.10GHz w/HT`}
                  productPrice='- - -'
                  productDescription={`32 Cores - 64 Total CPU Threads\n96GB DDR4 RAM480GB SSD ou 1TB SATA3 HDDHot-Swappable Drive Chassis *30Mbps (~10TB) Tráfego Mensal100Mbps Porta de Rede`}
                  productBtn='CONSULTE'
                />
              </Div>
            </Column>
            <Column
              mb={[30]}
            >
              <Div
                maxWidth={['100%', 328]}
              >
                <ProductItem
                  productName='E5-2695 V4'
                  productAdvantages={`2 x 18-Core Xeon E5-2695 V4 2.10GHz w/HT`}
                  productPrice='- - -'
                  productDescription={`36 Cores - 72 Total CPU Threads\n96GB DDR4 RAM\n480GB SSD ou 1TB SATA3 HDD\nHot-Swappable Drive Chassis *\n30Mbps (~10TB) Tráfego Mensal\n100Mbps Porta de Rede`}
                  productBtn='CONSULTE'
                />
              </Div>
            </Column>
          </Row>
          <Hr
            lineColor='lightPeriwinkle'
            maxWidth={[328, '100%', 1032, 1032]}
            style={{
              opacity: 0.5,
              margin: '0 auto'
            }}
          />
          <Row
            flexDirection={['column', 'row']}
            mt={40}
            mb={50}
          >
            <Column
              width={[1]}
              textAlign={['left', 'left', 'center', 'center']}
            >            
              <H4 style={{ textAlign: 'center' }}>Hardwares fisicamente isolados, de<br /> uso exclusivo para sua empresa.</H4>
            </Column>
          </Row>
          <Row
            css={listServer}
            mb={49.5}
          >
            <Div
              alignItems={'center'}
              flexDirection={['column', 'column', 'row']}
              textAlign={'center'}
              maxWidth={['100%', 422, 1020]}
              style={{
                margin: '0 auto'
              }}
            >
              <Column
                width={[1]}
                mb={[40, 40, 0, 0]}
              >            
                <Div
                  alignItems={'center'}
                >
                  <img src={ImgServidoresExclusivos} />
                  <H6>Um ou mais servidores<br /> exclusivos para usa empresa</H6>
                </Div>
              </Column>
              <Column
                width={[1]}
                mb={[40, 40, 0, 0]}
              >            
                <Div
                  alignItems={'center'}
                >
                  <img style={{ width: 160, marginBottom: 30 }} src={ImgFlexibilidade} />
                  <H6>Flexibilidade na <br />escolha do hadware</H6>
                </Div>
              </Column>
              <Column
                width={[1]}
                mb={[0]}
              >            
                <Div
                  alignItems={'center'}
                >
                  <img src={ImgMonitoramento} />
                  <H6>Monitoramento<br />proativo 24x7x365</H6>
                </Div>
              </Column>
            </Div>
          </Row>
          <Hr
            lineColor='lightPeriwinkle'
            maxWidth={[328, '100%', 1032, 1032]}
            style={{
              opacity: 0.5,
              margin: '0 auto'
            }}
          />
          <Row
            mb={30}
            mt={49.5}
          >
            <Div 
              flexDirection={['column', 'column', 'unset', 'unset']}
              maxWidth={['100%', 422, 860]}
              alignItems={'center'}
              style={{
                margin: '0 auto'
              }}
            >
              <Column
                width={[1, 1, 1/2, 1/2]}
                mb={[30, 30, 0, 0]}
              >
                <div css={listManager}>
                  <img src={ImgPortalDeGerenciamento} />
                  <H6>Portal de gerenciamento dos servidores intuitivo</H6>
                </div>
              </Column>
              <Column
                width={[1, 1, 1/2, 1/2]}
              >
                <div css={listManager}>
                  <img src={ImgIpmi} />
                  <H6>IPMI gerencie seu hardware remotamente (BIOS)</H6>
                </div>
              </Column>
            </Div>
          </Row>
          <Row
            mb={[50, 70]}
          >
            <Div 
              flexDirection={['column', 'column', 'unset', 'unset']}
              maxWidth={['100%', 422, 860]}
              alignItems={'center'}
              style={{
                margin: '0 auto'
              }}
            >
              <Column
                width={[1, 1, 1/2, 1/2]}
                mb={[30, 30, 0, 0]}
              >
                <div css={listManager}>
                  <img src={ImgMonitoramentoTempo} />
                  <H6>Monitoramento em<br /> tempo real</H6>
                </div>
              </Column>
              <Column
                width={[1, 1, 1/2, 1/2]}
              >
                <div css={listManager}>
                  <img src={ImgInspecaoSeguranca} />
                  <H6>Inspeção de segurança em 5 etapas</H6>
                </div>
              </Column>
            </Div>
          </Row>
          <Div
            alignItems={'center'}
          >
            <Button
              onClick={() => navigate('/gerenciamento-dedicado')}
              schema='secondary'
              color='white'
              variant='highEmphasis'
              width={['100%']}
              maxWidth={['328px']}
              style={{
                display: 'flex',
                border: 'none',
                margin: '0 auto',
                boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14)'
              }}
            >
              gerenciamento dedicado
            </Button>
          </Div>
        </Container>
      </section>
      <UnderstandTheDifference
        visible={modals.moreInfo}
        onClose={() => setModalVisibility('moreInfo')(false)}
      />
    </Fragment>
  )
}

PageTemplate.propTypes = {
  data: PropTypes.object
}

const StaticPage = ({data}) => {
  return (
    <Layout>
      <Seo title='Preços Dedicados DC Orlando - USA' />
      <PageTemplate
        title='Preços Dedicados DC Orlando - USA'
        data={data}
      />
    </Layout>
  )
}

StaticPage.propTypes = {
  data: PropTypes.object
}

export const query = graphql`
  query {
    image1: file(relativePath: { eq: "ilustra-o-home-1@3x.png" }) {
      childImageSharp {
        fluid(maxWidth: 487) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default StaticPage
